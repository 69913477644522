import React, { useCallback, useEffect, useState } from 'react';
import clsx from 'clsx';
import axios from 'axios';
// import { captureException } from '@sentry/gatsby';

import NoShowCalculator from '../../templates/noshow-calculator/noshow-form';
import Completed from '../../templates/noshow-calculator/completed';
import ReportsContent from '../../templates/noshow-calculator/reports';
import { getHubspotUtk } from '../../utils/getHubspotUtk';
import { NoShowStyles as NoShowMain } from '../../templates/noshow-calculator/styles';

const Calculator = props => {
  const hubspotFormId = '2b9a7a73-3aaf-453f-969a-724cd6b50816';
  const [step, setStep] = useState(1);
  const [hutk, setHutk] = useState();
  const [availableUTMs, setAvailableUTMs] = useState({});
  const isSales = props?.pageContext?.isSales;
  const [calculatorData, setCalculatorData] = useState({
    amount: '',
    aveNumberNoShow: '',
  });

  const [reportData, setReportData] = useState({
    weekly: 0,
    monthly: 0,
    annually: 0,
  });

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setHutk(getHubspotUtk(document));

      if (JSON.parse(window.sessionStorage.getItem('utms'))) {
        setAvailableUTMs(JSON.parse(window.sessionStorage.getItem('utms')));
      }
    }
  }, []);

  const handleRestart = useCallback(() => setStep(1), []);

  const handleComputeRange = useCallback(
    (
      range,
      amount = calculatorData.amount,
      numberNoShow = calculatorData.aveNumberNoShow
    ) => {
      const weekly = amount * numberNoShow;
      const monthly = amount * numberNoShow * (13 / 3);
      const annually = amount * numberNoShow * 52;

      setReportData({
        ...reportData,
        weekly,
        monthly,
        annually,
      });
    },
    [calculatorData.amount, calculatorData.aveNumberNoShow, reportData]
  );

  const handleNext = useCallback(
    (amount, aveNumberNoShow) => {
      setCalculatorData({
        ...calculatorData,
        amount,
        aveNumberNoShow,
      });

      if (!isSales) {
        setStep(2);
      } else {
        handleComputeRange('annually', amount, aveNumberNoShow);
        setStep(3);
      }
    },
    [calculatorData, handleComputeRange, isSales]
  );

  const handleViewReports = useCallback(
    (firstName, lastName, email, phone) => {
      handleComputeRange('annually');

      const fields = [];
      const url = `https://api.hsforms.com/submissions/v3/integration/submit/5424293/${hubspotFormId}`;

      fields.push(
        { name: 'firstName', value: firstName },
        { name: 'lastName', value: lastName },
        { name: 'email', value: email },
        { name: 'phone', value: phone }
      );

      if (availableUTMs) {
        Object.keys(availableUTMs).forEach(key => {
          if (availableUTMs[key] != null) {
            fields.push({ name: key, value: availableUTMs[key] });
          }
        });
      }

      const postData = {
        submittedAt: Date.now(),
        fields,
        context: {
          hutk,
          pageUri: 'https://www.shopmonkey.io/no-show-loss-calculator',
          pageName: 'No-show Loss Calculator Page',
        },
      };

      axios
        .post(url, postData)
        .then()
        .catch(err => {
          console.log(err);
          console.log(`No-show Loss Calculator Form Error - ${err}`);
        });
      setStep(3);
    },
    [availableUTMs, handleComputeRange, hutk]
  );

  return (
    <NoShowMain className={clsx(step === 3 && 'reports')}>
      <div className="container">
        {step !== 3 && (
          <div className="header-wrapper">
            <h1 className="header">
              See how customer no-shows impact your business.
            </h1>
          </div>
        )}

        {step === 1 && <NoShowCalculator onNext={handleNext} />}
        {step === 2 && <Completed onViewReports={handleViewReports} />}
      </div>
      {step === 3 && (
        <ReportsContent
          annualLoss={reportData.annually}
          results={[
            { title: 'weekly', value: reportData.weekly },
            { title: 'monthly', value: reportData.monthly },
            { title: 'annually', value: reportData.annually },
          ]}
          onRestart={handleRestart}
        />
      )}
    </NoShowMain>
  );
};

export default Calculator;
